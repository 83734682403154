/*jslint jquery:true, browser: true, indent: 4*/


// Dost neuniverzální "plugin" pro accordeon
$.fn.slideToggleAuto = function (duration) {
    var height, _self = this;

    if (this.is(':visible')) {
        this.slideUp({duration: duration, easing: 'linear'}).data('expanded', false);
    } else {
        if (this.data('autoheight')) {
            this.slideDown({duration: duration, easing: 'linear'}).data('expanded', true);
        } else {
            this.show().css({height: 'auto'});
            height = this.height();
            this.hide();

            this.data('autoheight', height);

            this.slideToggleAuto().data('expanded', true);

            $(window).one('resize', function () {
                _self.data('autoheight', false);
            });
        }
    }

    return this;
};


// DOCUMENT READY

$(function () {
    var $win = $(window),
            $doc = $(document);

    // Fixace a rozbalování menu

    (function () {
        var $menu = $('#mainMenu'),
                breakpoint = $menu.offset().top,
                curr = $doc.scrollTop(),
                prev = 0;

        $win.on('scroll', function () {
            curr = $doc.scrollTop();
            // ověřuju překročení breakpointu, abych zbytečně nemanipuloval s třídami při každém scrollu
            if ((prev - breakpoint) * (curr - breakpoint) <= 0) {
                $menu.toggleClass('fixed', $doc.scrollTop() >= breakpoint);
            }
            prev = curr;
        }).trigger('scroll');

        $menu.find('.menuToggle').click(function () {
            $menu.toggleClass('open');
        });
        $menu.find('ul').click(function () {
            $menu.removeClass('open');
        });
    })();

    // inpage odkazy a animace scrollu
    $('body').on('click', 'a[href^=#]', function (evt) {
        evt.preventDefault();
        if (!$(this).data("tab")) {
            $('html, body').animate({
                scrollTop: $($(evt.target).attr('href')).offset().top - 20
            }, 300);
        }
    });

    // Fancyboxy

    $('#program .imgGrid a, #courses .images a').fancybox();

    // Scroll Spy

    $('body').scrollspy({target: '#mainMenu', offset: 50});

    // Harmonika v Kroužcích

    (function () {
        var duration = 300;

        $('.accordion .panel').on('click', '.toggle, header:not(.expanded header)', function () {
            var $panel = $(this).closest('.panel'),
                    $content = $panel.find('.content');

            $content.slideToggleAuto(duration);
            $panel
                    .toggleClass('expanded', $content.data('expanded'))
                    .siblings('.panel')
                    .removeClass('expanded')
                    .find('.content')
                    .slideUp({duration: duration, easing: 'linear'});

        }).first().find('header').click();
    })();

    // Carousel v referencích

    $('ul.references').slick({
        slide: 'li',
        adaptiveHeight: true,
        fade: true
    });

    // TABY V PROGRAMECH
    $('ul.tabs').each(function () {
        var $active, $content, $links = $(this).find('a');
        $active = $($links.filter('.active')[0] || $links[0]);
        $active.addClass('active');

        $content = $($active.data("tab"));

        $links.not($active).each(function () {
            $($(this).data("tab")).hide();
        });

        $(this).on('click', 'a', function (e) {
            $active.removeClass('active');
            $content.hide();
            $active = $(this);
            $content = $($(this).data("tab"));
            $active.addClass('active');
            $content.show();
            e.preventDefault();
        });
    });

    // SHOW MORE
    $(".showMore").on("click", function (e) {
        e.preventDefault();
        var showMore = $(this);
        var page = showMore.data("page");
        var program = showMore.data("program");
        $.ajax({
            url: window.location.pathname,
            type: "POST",
            data: {page: page, program: program}
        }).done(function (msg) {
            if (msg.images) {
                var html = "";
                $.each(msg.images, function (index, item) {
                    if (index % 3 == 0) {
                        html += "<div class='row'>";
                    }
                    html += '<a href="' + item.big + '" data-fancybox-group="group' + program + '">';
                    html += '<img src="' + item.small + '" alt=" ' + item.alt + ' ">';
                    html += '</a>';
                    if (index % 3 == 2 || (index + 1) == msg.images.length) {
                        html += "</div>";
                    }
                });
            }
            showMore.prev(".imgGrid").append(html);

            $('html, body').animate({
                scrollTop: showMore.offset().top - 500
            }, 800);

            if (msg.total > page * 6) {
                showMore.data("page", page + 1);
            } else {
                showMore.remove();
            }
        });
    });

var timeout, timeoutGirl;
$('.manOutline').hover(
    function() {
       clearTimeout(timeout);
              $('.ideaMan').css('display', 'block');
    },
    function(){
         timeout = setTimeout(function(){   
       $('.ideaMan').css('display', 'none');
       }, 1000);
    }
);

$('.womanOutline').hover(
    function() {
       clearTimeout(timeoutGirl);
              $('.ideaWoman').css('display', 'block');
    },
    function(){
         timeoutGirl = setTimeout(function(){   
       $('.ideaWoman').css('display', 'none');
       }, 1000);
    }
);



});
